import React from 'react'

const Status = ({ value }) => {
  if (value) {
    if (value === 'correct') {
      return <div style={{ color: '#3DC45C', textAlign: 'center', fontWeight: 'bold', padding: 5, borderRadius: 4 }}>Aprovado</div>
    }
    if (value === 'incorrect') {
      return <div style={{ color: 'red', textAlign: 'center', fontWeight: 'bold', padding: 5, borderRadius: 4 }}>Recusado</div>
    }
    if (value === 'timeout') {
      return <div style={{ color: 'red', textAlign: 'center', fontWeight: 'bold', padding: 5, borderRadius: 4 }}>Timeout</div>
    }
    return ''
  }
  return ''
}

export default Status
