export const textLabels = {
    body: {
        noMatch: 'Desculpe, nenhum registro encontrado',
        toolTip: 'Ordenar',
    },
    pagination: {
        next: 'Próxima Página',
        previous: 'Página anterior',
        rowsPerPage: 'Linhas por página:',
        // displayRows: 'of',
    },
    toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Colunas Visíveis',
        filterTable: 'Filtrar tabela',
    },
    filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'REINICIAR',
    },
    viewColumns: {
        title: 'Mostrar Colunas',
        titleAria: 'Exibir/Mostrar colunas da tabela',
    },
    selectedRows: {
        text: 'Linha(s) selecionadas',
        delete: 'Apagar',
        deleteAria: 'Apagar linhas selecionadas',
    },
}
