import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

class Timer extends Component {
  state = { seconds: this.props.seconds, popTime: false }

  componentDidMount() {
    this.timer = setInterval(this.tick, 1000)
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.question !== this.props.question) {
      clearInterval(this.timer)
      await this.setState({ seconds: this.props.seconds })
      this.timer = setInterval(this.tick, 1000)
    }
    if (this.state.seconds === 0) {
      await this.props.popAction(this.state.seconds)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  tick = () => {
    const { seconds } = this.state
    if (seconds > 0) {
      this.setState({ seconds: seconds - 1 })
    } else {
      clearInterval(this.timer)
    }
  }

  renderColor = (seconds) => {
    if (seconds > 5) {
      return { background: '#3DC45C' }
    }
    if (seconds <= 5 && seconds > 1) {
      return {
        background: '#ffb600',
      }
    }
    return {
      background: 'red',
    }
  }

  render() {
    const { seconds } = this.state
    const { classes } = this.props

    return (
      <p className={classes.timer} style={this.renderColor(seconds)}>
        00:
        {seconds < 10 ? `0${seconds}` : seconds}
      </p>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Timer)
