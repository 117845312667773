import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'

import {
  cepMask,
  cpfMask,
  dateMask,
  phoneMask,
  cnpjMask,
} from './Masks'
import './styles.css'

const mask = (name) => {
  if (name === 'cpf') return cpfMask
  if (name === 'cnpj') return cnpjMask
  if (name === 'phone_number') return phoneMask
  if (name === 'cep') return cepMask
  if (name === 'date') return dateMask
  return null
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask(props.name)}
      placeholderChar="_"
      // showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export function MaskInput({ name, onChange, value, label, variant, className, errorMsg }) {

  const [wasBlurred, setWasBlurred] = useState(false)

  return (
    <TextField
      value={value}
      onChange={onChange}
      InputProps={name && {
        inputComponent: TextMaskCustom,
      }}
      name={name}
      label={label}
      variant={variant}
      className={className}
      autoComplete="off"
      error={!!errorMsg && wasBlurred}
      helperText={wasBlurred ? errorMsg : null}
      onBlur={() => setWasBlurred(true)}
    />
  )
}
