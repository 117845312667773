import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, FormControl, Select, MenuItem, Input } from '@material-ui/core'
import InovaApi from 'helpers/apiRequest'
import Typography from '@material-ui/core/es/Typography/Typography'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Slider from '@material-ui/core/Slider'
// import { Slider } from "material-ui-slider"

import axios from 'axios'
import { toast } from 'react-toastify'
import { validateTypeUser } from 'helpers/validateTypeUser'
import styles from './styles'

class QuizConfiguration extends React.PureComponent {
  state = {
    cognito_sub: this.props.auth.sub,
    number_questions: 4,
    number_answers: 4,
    number_attempts: 2,
    format: null,
    timer: 10,
    open: false,
    method: 'POST',
    id: null,
    disableMultChoiceConfig: false,
  }

  api = new InovaApi()

  componentDidMount() {
    validateTypeUser(this.props, this.props.auth.groups)
    this.loadData()
  }

  loadData = async () => {
    const { sub } = this.props.auth
    try {
      const allConfigs = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_QUIZ_API}/settings?cognito_sub=${sub}`,
        headers: {
          'x-api-key': this.props.auth.api_key,
        },
      })

      if (allConfigs.data.length !== 0) {
        this.setState({ method: 'PUT' })
      }
      if (allConfigs.data.length > 0) {
        this.setState({
          id: allConfigs.data[0].id,
          number_questions: allConfigs.data[0].number_questions,
          number_answers: allConfigs.data[0].number_answers,
          number_attempts: allConfigs.data[0].number_attempts,
          format: allConfigs.data[0].format,
          timer: allConfigs.data[0].timer,
          disableMultChoiceConfig: allConfigs.data[0].format === 'true_false',
        })
      }
    } catch (error) {
      if (error.response) {
        return toast.error('Houve um problema na comunicação com o servidor.')
        // this.api.checkStatus(error.response.status, inova_key)
      }
    }
    return false
  }

  handleChangeNumberQuestions = (event, value) => {
    this.setState({ number_questions: value })
  }

  handleChangeNumberAlternatives = (event, value) => {
    this.setState({ number_answers: value })
  }

  handleChangeNumberTentatives = (event, value) => {
    this.setState({ number_attempts: value })
  }

  handleChangeResponseTime = (event, value) => {
    this.setState({ timer: value })
  }

  handleChangeTypeQuiz = event => {
    this.setState({ format: event.target.value, disableMultChoiceConfig: event.target.value === 'true_false' })
  }

  handleChangeStatus = event => {
    this.setState({ status: event.target.value })
  }

  handleChangeCheckbox = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  postData = async () => {
    const setting = { ...this.state }

    delete setting.status
    delete setting.id
    delete setting.open
    delete setting.method
    delete setting.id
    delete setting.cognito_sub

    const { method, id, cognito_sub } = this.state

    const data = {
      setting: {
        ...setting,
      },
      cognito_sub,
      id,
    }

    const newConfig = await axios({
      method,
      url:
        method === 'GET'
          ? `${process.env.REACT_APP_QUIZ_API}/settings/${id}`
          : `${process.env.REACT_APP_QUIZ_API}/settings`,
      headers: {
        'x-api-key': this.props.auth.api_key,
        'Content-Type': 'application/json',
      },
      data,
    })

    if (newConfig.status === 200) {
      toast.success('Configuração salva com sucesso.')
    }

    if (newConfig.data) {
      this.setState({
        number_questions: newConfig.data.number_questions,
        number_answers: newConfig.data.number_answers,
        number_attempts: newConfig.data.number_attempts,
        format: newConfig.data.format,
        timer: newConfig.data.timer,
      })
    }

    if (newConfig.status === 403) {
      toast.warn(
        `Sua chave de api não permite o acesso a essa funcionalidade. Por favor, entre em contato. - Status: ${newConfig.status}`
      )
    }
    if (newConfig.status === 429) {
      toast.warn(`Verifique seu consumo. Por favor, entre em contato. - Status: ${newConfig.status}`)
    }
  }

  marks = [
    {
      value: 10,
      label: '10s',
    },
    {
      value: 15,
      label: '15s',
    },
    {
      value: 20,
      label: '20s',
    },
    {
      value: 30,
      label: '30s',
    },
  ]

  render() {
    const { classes } = this.props
    const { number_questions, format, timer, number_answers, number_attempts } = this.state

    return (
      <main>
        <div className={classes.paper}>
          <div className="flex flex-col">
            <Typography variant="h6" style={{ marginBottom: 30 }}>
              Configurar o Quiz
            </Typography>
            <div className={classes.configLine}>
              <Typography variant="body2" className={classes.sliderTitle}>
                Número de Questões Corretas
              </Typography>
              <Slider
                className={classes.slider}
                value={number_questions}
                min={3}
                max={4}
                step={1}
                name="number_questions"
                onChange={this.handleChangeNumberQuestions}
              />
              {number_questions && (
                <Typography variant="body2" className={classes.sliderValue}>
                  {number_questions}
                </Typography>
              )}
            </div>
            <div className={classes.configLine}>
              <Typography variant="body2" className={classes.sliderTitle}>
                Quantidade de Alternativas
              </Typography>
              <Slider
                className={classes.slider}
                value={number_answers}
                min={4}
                max={5}
                step={1}
                name="number_answers"
                onChange={this.handleChangeNumberAlternatives}
                disabled={this.state.disableMultChoiceConfig}
              />
              {number_answers && (
                <Typography variant="body2" className={classes.sliderValue}>
                  {number_answers}
                </Typography>
              )}
            </div>

            <div className={classes.configLine}>
              <Typography variant="body2" className={classes.sliderTitle}>
                Quantidade de Tentativas
              </Typography>
              <Slider
                className={classes.slider}
                value={number_attempts}
                min={1}
                max={3}
                step={1}
                marks={true}
                name="number_attempts"
                onChange={this.handleChangeNumberTentatives}
              />

              {number_attempts && (
                <Typography variant="body2" className={classes.sliderValue}>
                  {number_attempts}
                </Typography>
              )}
            </div>

            <div className={classes.configLine}>
              <Typography variant="body2" className={classes.sliderTitle}>
                Tempo de Resposta
              </Typography>
              <Slider
                className={classes.slider}
                value={timer}
                min={10}
                max={30}
                step={null}
                marks={this.marks}
                name="timer"
                onChange={this.handleChangeResponseTime}
              />
              {timer && (
                <Typography variant="body2" className={classes.sliderValue}>
                  {`${timer}s`}
                </Typography>
              )}
            </div>

            <div className={classes.configLine}>
              <Typography variant="body2" className={classes.sliderTitle}>
                Formato
              </Typography>
              <FormControl className={classes.formControl}>
                <Select
                  value={format}
                  onChange={this.handleChangeTypeQuiz}
                  input={<Input name="type-quiz" id="type-quiz" />}
                >
                  <MenuItem value="true_false">Verdadeiro/Falso</MenuItem>
                  <MenuItem value="multiple_choice">Multipla Escolha</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button variant="contained" color="primary" onClick={this.postData} style={{ width: 300 }}>
              Salvar
            </Button>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(QuizConfiguration))
