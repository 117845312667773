import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import QuizConfiguration from 'pages/QuizConfiguration'
import Dashboard from 'pages/Dashboard'
import QuizContent from 'pages/QuizContent'
import Login from 'pages/Login'
import ConfirmRecoverPassword from 'pages/ConfirmRecoverPassword'
import RecoverPassword from 'pages/RecoverPassword'
import ChangePassword from 'pages/ChangePassword'
import PrivateRoute from './PrivateRoute'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/confirm-recover-password" exact component={ConfirmRecoverPassword} />
      <Route path="/recover-password" exact component={RecoverPassword} />
      <Route path="/change-password" exact component={ChangePassword} />
      <PrivateRoute path="/dashboard" component={Dashboard} title="Dashboard" />
      <PrivateRoute path="/configuration" component={QuizConfiguration} title="Configurações" />
      <PrivateRoute path="/quiz" component={QuizContent} title="Quiz" />
      <Route path="*" component={() => <h1>Esta página não existe!</h1>} />
    </Switch>
  </BrowserRouter>
)

export default Routes
