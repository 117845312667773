import React from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
// import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'

import {
  // ExpansionPanel,
  Checkbox,
  CircularProgress,
  Divider,
  ExpansionPanelDetails,
  Icon,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {CardTitle, StatusConsult} from 'components/styledComponents'
import styles from './styles'

const styledBy = (property, mapping) => props => mapping[props[property]]


const ExpansionPanel = withStyles({
  root: {
    border: styledBy('color', {
      default: '2px solid rgba(0, 0, 0, .125)',
      green: '2px solid #2DAA00',
      red: '2px solid #d0021b',
    }),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    borderRadius: 15,
  },
  rounded:{
    '&:first-child': {
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    '&:last-child': {
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
  },
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: styledBy('color', {
      default: '#FFF',
      green: '#F8F8F8',
      red: '#FEF0F0',
    }),
    '&$focused': {
      backgroundColor: styledBy('color', {
        default: '#FFF',
        green: '#F8F8F8',
        red: '#FEF0F0',
      }),
    },
    borderRadius: 15,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})(MuiExpansionPanelSummary)

export default function ControlledExpansionPanels({
  title,
  children,
  processed,
  loading,
  consists,
  onChangeCheckbox,
  checkboxChecked,
  checkboxIndeterminate,
  checkboxDisabled,
}) {
  const classes = styles()
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleChangeCheckbox = () => (event, isChecked) => {
    event.stopPropagation()
    if (_.isFunction(onChangeCheckbox)) onChangeCheckbox(isChecked)
  }

  React.useEffect(() => {
    if(processed === true){
      setExpanded('panel1')
    }
    if(loading === true){
      setExpanded(false)
    }
  }, [processed, loading])

  function renderColor(){
    if (processed) {
      return 'green'
    }
    if (consists) {
      return 'red'
    }

    return 'default'
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        square={false}
        color={renderColor()}
        // disabled={!processed}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          color={renderColor()}
        >
          <Checkbox
            checked={checkboxChecked}
            indeterminate={checkboxIndeterminate}
            onChange={handleChangeCheckbox()}
            onClick={(e) => e.stopPropagation()}
            disabled={checkboxDisabled}
          />
          <CardTitle processed={processed ? 1 : 0} loading={loading ? 1 : 0}>{title}</CardTitle>
          <span className={classes.subtitle}>
            {loading && (
              <CircularProgress
                className={classes.progress}
                style={{
                  width: 25,
                  height: 25,
                }}
              />
            )}
            {!loading && processed && (
              <StatusConsult>
                  OK
                <Icon style={{color: '#2DAA00', margin: 5}}>check_circle</Icon>
                {/* <Icon style={{color: '#CB184B'}}>error</Icon> */}
              </StatusConsult>
            )}
          </span>
        </ExpansionPanelSummary>
        <Divider />
        {loading ? <div /> : (
          <ExpansionPanelDetails>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {children}
            </div>
          </ExpansionPanelDetails>
) }
      </ExpansionPanel>
    </div>
  )
}
