import React from 'react'
import LoadingStyle from './style'
import CircularProgress from '@material-ui/core/CircularProgress'

export const Loading = ({ visible }) =>
    visible ? (
        <LoadingStyle>
            <CircularProgress color="primary" />
        </LoadingStyle>
    ) : null
