import React from 'react'
import {
  ThumbUp, ThumbDown, AccessTime,
} from '@material-ui/icons'

const Answers = ({ data }) => {
  if (data) {
    if (!data.time_expired && JSON.stringify(data.correct) === 'true') {
      return <ThumbUp style={{ color: '#3DC45C' }} />
    }
    if (!data.time_expired && JSON.stringify(data.correct) === 'false') {
      return <ThumbDown style={{ color: 'red' }} />
    }
    if (JSON.stringify(data.time_expired) === 'true') {
      return <AccessTime style={{ color: 'red' }} />
    }
    return ''
  }
  return ''
}

export default Answers
