export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
  },
  aside: {
    display: 'flex',
    flexDirection: 'row',
    background:
      'linear-gradient(0deg, rgba(203,25,76,0.8), rgba(203,25,76,1))',
      // 'linear-gradient(0deg, rgba(203,25,76,0.8), rgba(203,25,76,1)),url(assets/images/background/bg-check2.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    height: '100%',
    width: '40%',
    justifyContent: 'space-between',
    padding: 40,
    color: '#FFF',
  },
  logo: {
    width: 250,
  },
  title: {
    marginBottom: 50,
  },
  main: {
    height: '100%',
    width: '60%',
    padding: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textField: {
    width: 400,
    marginBottom: 30,
  },
  button: {
    width: 300,
    height: '50px',
    borderRadius: 30,
    marginTop: '50px',
    marginBottom: 20,
    '&:hover':{
      backgroundColor: theme.palette.primary.hover,
    },
  },
  progress: {
    margin: theme.spacing(1),
  },
})
