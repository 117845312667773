import { toast } from 'react-toastify'

export function validateTypeUser(props, groups) {
  if (groups) {
    const admin = groups.indexOf('Admin')
    console.log('Verificando tipo de usuário...')
    if (admin === -1) {
      toast.error('Você não tem permissão para acessar essa página.')
      return props.history.push({
        pathname: '/quiz',
      })
    }
  }
  return false
}
