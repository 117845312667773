import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import { Reducers } from './rootReducers'
import rootSaga from './rootSagas'

const persistConfig = {
  key: 'inova@quiz',
  storage,
  whitelist: ['auth'],
  // blacklist: [],
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]

const persistedReducer = persistReducer(persistConfig, Reducers)
const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...middlewares))
    : applyMiddleware(...middlewares)
)

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export { store, persistor }
