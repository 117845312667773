export default (theme) => ({
  root: {},
  paper: {
    padding: '20px',
    marginBottom: '30px',
  },
  responseItem: {
    textTransform: 'capitalize',
  },
  textField: {
    width: 300,
    padding: 15,
    borderBottom: '1px solid #d2cdcd',
  },
  linkButton: {
    backgroundColor: '#CB194C',
    color: '#FFF !important',
    padding: '6px 16px',
    fontSize: '1.4rem',
    minWidth: 64,
    boxSizing: 'border-box',
    minHeight: 36,
    lineHeight: 1.3125,
    fontFamily: 'Muli, Roboto, "Helvetica", Arial, sans-serif',
    fontWeight: 600,
    borderRadius: 4,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
      backgroundColor: '#bf1343',
    },
  },
  formControl: {
    width: 300,
  },
  configLine: {
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  slider: {
    width: 300,
  },
  sliderValue: {
    marginLeft: '25px !important',
    padding: '8px 15px',
    border: '1px solid #dedede',
    borderRadius: '15px',

  },
  sliderTitle: {
    marginRight: 30,
    width: 200,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    maxWidth: '450px',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
