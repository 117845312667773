import axios from 'axios'
import { toast } from 'react-toastify'

export default class InovaApi {
  axios

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL_API,
      timeout: 30000,
    })
  }

  analyticalReports = async (key, user, initial, final, type, page) => {
    try {
      const allConfigs = await this.axios({
        method: 'GET',
        url: `quiz/v1/quiz/reports/analytical/?user=${user}&start_date=${initial}&final_date=${final}&type=${type}&page=${page}`,
        headers: {
          'x-api-key': key,
        },
      })
      if (allConfigs.data) {
        return allConfigs.data
      }
    } catch (error) {
      if (error.response) {
        this.checkStatus(error.response.status, key)
      }
    }
  }

  syntheticReports = async (key, user, initial, final, type) => {
    try {
      const allConfigs = await this.axios({
        method: 'GET',
        url: `quiz/v1/quiz/reports/synthetic/?user=${user}&start_date=${initial}&final_date=${final}&type=${type}`,
        headers: {
          'x-api-key': key,
        },
      })
      if (allConfigs.data) {
        return allConfigs.data
      }
    } catch (error) {
      if (error.response) {
        this.checkStatus(error.response.status, key)
      }
    }
  }

  allConfigs = async (key, user) => {
    try {
      const allConfigs = await this.axios({
        method: 'GET',
        url: `/quiz/v1/quiz/settings`,
        headers: {
          'x-api-key': key,
        },
      })
      console.log(allConfigs)
      if (allConfigs.data) {
        return allConfigs.data
      }
    } catch (error) {
      if (error.response) {
        this.checkStatus(error.response.status, key)
      }
    }
  }

  newConfig = async (key, setting) => {
    try {
      const allConfigs = await this.axios({
        method: 'GET',
        url: `/quiz/v1/quiz/settings`,
        headers: {
          'x-api-key': key,
          'Content-Type': 'application/json',
        },
        setting,
      })
      if (allConfigs.data) {
        return allConfigs.data
      }
    } catch (error) {
      if (error.response) {
        this.checkStatus(error.response.status, key)
      }
    }
  }

  checkStatus = (status, key) => {
    // toast.info(`Recebido`)
    const api_key = key.slice(0, 5)

    if (status === 403) {
      toast.warn(
        `Problemas na chave de api. Por favor, entre em contato. - Status: ${status} - Chave de api: ${api_key}`,
      )
    }
    if (status === 429) {
      toast.warn(
        `Verifique seu consumo. Por favor, entre em contato. - Status: ${status} - Chave de api: ${api_key}`,
      )
    }
  }
}
