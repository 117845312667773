import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  // Checkbox,
  Radio,
  RadioGroup,
  // TextField,
} from '@material-ui/core'
import { MaskInput } from 'components/MaskedInput'
import { Loading } from 'components'
import Typography from '@material-ui/core/es/Typography/Typography'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
  isValid as isValidCpf,
  strip as stripCpf,
  // cpfMask,
} from 'helpers/cpf'
import InovaApi from 'helpers/apiRequest'
import moment from 'moment'
import Timer from './Timer'
import styles from './styles'

class QuizContent extends React.Component {
  state = {
    currentQuestion: 0,
    disabledInput: false,
    quiz: {
      questions: [],
      quiz_key: '',
      loaded: false,
    },
    showLoading: false,
    userAnswers: [],
    valueCpf: '',
    quizResult: null,
    quizType: '',
    allQuizzes: [],
    quizStartTime: null,
    seconds: null,
    popTimer: false,
    extraQuestion: false,
  }

  api = new InovaApi()

  resetState = () =>
    this.setState({
      currentQuestion: 0,
      disabledInput: false,
      quiz: {
        questions: [],
        quiz_key: '',
        loaded: false,
      },
      showLoading: false,
      userAnswers: [],
    })

  resetQuiz = async () => {
    // console.log('aqui')
    await this.resetState()
    await this.setState({
      quizResult: null,
      valueCpf: '',
      quizType: '',
    })
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    console.log(this.props.auth.api_key)
    const { sub } = this.props.auth
    try {
      const allConfigs = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_QUIZ_API}/settings?cognito_sub=${sub}`,
        headers: {
          'x-api-key': this.props.auth.api_key,
        },
      })
      // console.log(allConfigs)
      if (allConfigs.data.length === 0) {
        if (this.props.auth.groups.indexOf('Admin') > -1) {
          this.props.history.push({
            pathname: '/configuration',
          })
          return toast.warn('Nenhuma configuração de quiz existente. Realize a configuração do quiz.')
        }
        return toast.warn('Nenhuma configuração de quiz existente. Entre em contato com o administrador.')
      }
      return await this.setState({
        allQuizzes: allConfigs.data,
        seconds: allConfigs.data[0].timer,
      })
    } catch (error) {
      if (error.response) {
        return toast.error('Houve um problema ao carregar os tipos de quiz.')
        // this.api.checkStatus(error.response.status, inova_key)
      }
    }
    return false
  }

  onChangeCpf = e => {
    const { value } = e.target
    const valueCpf = stripCpf(value)
    this.setState({ valueCpf })
  }

  onChangeAnswer = async e => {
    const { userAnswers, currentQuestion } = this.state

    userAnswers[currentQuestion] = { ...userAnswers[currentQuestion], value: e.target.value }
    await this.setState({ userAnswers })
    // console.log(this.state.userAnswers)
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeCheckbox = name => event => {
    this.setState({
      [name]: event.target.checked,
      quizType: event.target.value,
    })
  }

  onClickNextQuestion = () => {
    const { userAnswers, currentQuestion } = this.state
    // console.log(seconds_base)

    userAnswers[currentQuestion] = { ...userAnswers[currentQuestion], timer: moment() }
    // console.log('--------', userAnswers)

    if (typeof userAnswers[currentQuestion].value === 'undefined') return
    if (userAnswers[currentQuestion].value === '') return

    this.setState({ currentQuestion: currentQuestion + 1, userAnswers })
  }

  startQuiz = async cpf => {
    // const { quizType } = this.state
    try {
      const isValid = isValidCpf(cpf)
      if (isValid) {
        await this.setState({ showLoading: true, disabledInput: true })

        const data = {
          cpf: cpf,
          cognito_sub: this.props.auth.username,
          // setting: '',
        }
        const res = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_QUIZ_API}/questions`,
          headers: {
            'x-api-key': this.props.auth.api_key,
          },
          data,
        })

        const allQuestions = res.data.questions

        const userAnswers = []

        allQuestions.map(() => {
          return userAnswers.push({ value: '', timer: moment() })
        })

        await this.setState({
          quiz: {
            questions: res.data.questions,
            quiz_key: res.data.quiz_key,
            loaded: true,
          },
          userAnswers,
          quizStartTime: moment(),
        })
        // console.log('res', res.data, this.state.quiz)
      }
    } catch (error) {
      if (error.response) {
        // this.api.checkStatus(error.response.status, inova_key)
        if (error.response.data.msg_infos) {
          toast.warn(`${error.response.data.msg_infos[0].msg}`)
        }
        if (error.response.data.msg_errors) {
          toast.warn(`${error.response.data.msg_errors[0].msg}`)
        }
      }
    } finally {
      this.setState({ showLoading: false, disabledInput: false })
    }
  }

  onSubmitQuiz = async timeSeconds => {
    const { userAnswers, quiz, quizStartTime, currentQuestion } = this.state

    try {
      if (userAnswers.length !== quiz.questions.length) return

      const answers = userAnswers.map((item, index) => {
        let answer = {}
        if (index === 0) {
          answer = {
            value: item.value,
            timer: Math.floor(item.timer.diff(quizStartTime) / 1000) + 1,
          }
          // console.log(answer)
          return answer
        }
        if (index === quiz.questions.length - 1) {
          answer = {
            value: item.value,
            timer: Math.floor(moment().diff(userAnswers[index - 1].timer) / 1000) + 1,
          }
          // console.log(answer)
          return answer
        }
        answer = {
          value: item.value,
          timer: Math.floor(item.timer.diff(userAnswers[index - 1].timer) / 1000) + 1,
        }
        // console.log(answer)
        return answer
      })
      let data = null
      if (timeSeconds === 0) {
        answers[currentQuestion].value = ''
        const finalAnswer = answers
          .map((item, index) => {
            if (index === currentQuestion) {
              return item
            }
            if (item.value !== '') {
              return item
            }
            return false
          })
          .filter(item => item !== false)
          .map(item => {
            if (item.value === '') {
              // TODO - Fixing counter error
              return {
                value: item.value,
                // timer: Math.abs(item.timer) + seconds,
                timer: 35,
              }
            }
            return item
          })

        data = {
          quiz_key: quiz.quiz_key,
          answers: finalAnswer,
          timer: Math.floor(moment().diff(quizStartTime) / 1000) + 1,
        }
      }
      if (timeSeconds !== 0) {
        data = {
          quiz_key: quiz.quiz_key,
          answers,
          timer: Math.floor(moment().diff(quizStartTime) / 1000) + 1,
        }
      }

      await this.setState({ showLoading: true })

      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_QUIZ_API}/result`,
        headers: {
          'x-api-key': this.props.auth.api_key,
        },
        data,
      })
      if (res.data.questions && res.data.questions.length > 0) {
        const allQuestions = res.data.questions

        const extraAnswers = []

        allQuestions.map(() => {
          return extraAnswers.push({ value: '', timer: moment() })
        })

        return await this.setState({
          extraQuestion: true,
          quiz: {
            questions: res.data.questions,
            quiz_key: res.data.quiz_key,
            loaded: true,
          },
          userAnswers: extraAnswers,
          quizStartTime: moment(),
          showLoading: false,
          currentQuestion: 0,
          popTimer: false,
        })
      }

      await this.setState({
        quizResult: res.data.status,
        showLoading: false,
      })

      return this.resetState()
    } catch (error) {
      if (error.response) {
        // this.api.checkStatus(error.response.status, inova_key)
        toast.warn('Houve um erro ao enviar as respostas!')
      }
    }
  }

  renderQuestion = questions => {
    const { userAnswers, currentQuestion } = this.state
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ fontSize: 16, color: '#333' }}>
          {questions[currentQuestion].title}
        </FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="full"
          onChange={this.onChangeAnswer}
          value={userAnswers[currentQuestion].value}
        >
          {questions[currentQuestion].answers.map((answer, i) => (
            <FormControlLabel
              value={
                answer === 'Verdadeiro' || answer === 'Falso' ? (answer === 'Verdadeiro' ? 'true' : 'false') : answer
              }
              key={i}
              control={(
                <Radio
                  style={{
                    color: '#CB194C',
                  }}
                />
)}
              label={answer}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  renderQuiz = () => {
    const { userAnswers, quiz, currentQuestion, seconds, popTimer } = this.state
    const { classes } = this.props

    return (
      <div>
        {this.renderQuestion(quiz.questions)}
        <div style={{ marginTop: '10px' }}>
          {currentQuestion + 1 === quiz.questions.length ? (
            <div className={classes.container}>
              <Button
                disabled={
                  typeof userAnswers[currentQuestion].value === 'undefined' || userAnswers[currentQuestion].value === ''
                }
                color="primary"
                variant="contained"
                onClick={() => this.onSubmitQuiz()}
              >
                Enviar
              </Button>
              <Timer
                seconds={seconds}
                question={currentQuestion}
                popAction={this.onSubmitQuiz}
                popTimer={() => this.setState({ popTimer: !popTimer })}
              />
            </div>
          ) : (
            <div className={classes.container}>
              <Button color="primary" variant="contained" onClick={this.onClickNextQuestion}>
                Próxima
              </Button>
              <Timer
                seconds={seconds}
                question={currentQuestion}
                popAction={this.onSubmitQuiz}
                popTimer={() => this.setState({ popTimer: !popTimer })}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderQuizResult = () => {
    const { quizResult } = this.state
    if (quizResult === 'correct') {
      return (
        <div>
          <Typography variant="body1" style={{ fontSize: '22px' }}>
            <span role="img" aria-label="Valid">
              ✅
            </span>
            {' '}
            Quiz Respondido Corretamente!!
          </Typography>
          <Button color="primary" variant="contained" onClick={this.resetQuiz} style={{ marginTop: 30, width: 300 }}>
            Novo Quiz
          </Button>
        </div>
      )
    }
    if (quizResult === 'incorrect') {
      return (
        <div>
          <Typography variant="body1" style={{ fontSize: '22px', color: 'red' }}>
            <span role="img" aria-label="Invalid">
              🚫
            </span>
            {' '}
            Quiz Incorreto!!
          </Typography>
          <Button color="primary" variant="contained" onClick={this.resetQuiz} style={{ marginTop: 30, width: 300 }}>
            Novo Quiz
          </Button>
        </div>
      )
    }
    if (quizResult === 'timeout') {
      return (
        <div>
          <Typography variant="body1" style={{ fontSize: '22px', color: 'red' }}>
            <span role="img" aria-label="Invalid">
              ⏰
            </span>
            {' '}
            Tempo Excedido
          </Typography>
          <Button color="primary" variant="contained" onClick={this.resetQuiz} style={{ marginTop: 30, width: 300 }}>
            Novo Quiz
          </Button>
        </div>
      )
    }
  }

  render() {
    const { classes } = this.props
    const { valueCpf, quizResult, quiz, showLoading, allQuizzes } = this.state
    return (
      <main style={{ paddingBottom: 20 }}>
        {!showLoading && quizResult === null && (
          <div className="flex flex-col">
            <div style={{ marginBottom: 20 }}>
              <Typography variant="body2" style={{ marginRight: 10 }}>
                Digite um CPF:
              </Typography>
              <MaskInput
                className={classes.textField}
                label="Inserir CPF"
                onChange={this.onChangeCpf}
                name="cpf"
                variant="outlined"
                value={valueCpf}
                disabled={quiz.loaded} // TODO: set from state
                // errorMsg={valueValid ? null : "CPF inválido"}
              />
            </div>
            {/* <div
              style={{
                marginBottom: 20,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography
                  variant="body2"
                  style={{ marginRight: 10 }}
                >
                  Tipo de Quiz:
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={quizType}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'quizType',
                      id: 'quizType',
                    }}
                    disabled={quiz.loaded}
                  >
                    <MenuItem style={{ width: 550 }}>
                      <strong
                        style={{
                          paddingRight: 10,
                          width: 150,
                        }}
                      >
                        Formato
                      </strong>
                      <strong
                        style={{
                          padding: '0 10px',
                          width: 90,
                        }}
                      >
                        Perguntas
                      </strong>
                      <strong
                        style={{
                          padding: '0 10px',
                          width: 190,
                        }}
                      >
                        Número de alternativas
                      </strong>
                      <strong
                        style={{
                          padding: '0 10px',
                          width: 70,
                        }}
                      >
                        Timer
                      </strong>
                    </MenuItem>
                    {allQuizzes.map((quiz) => (
                      <MenuItem
                        value={quiz.id}
                        key={quiz.id}
                      >
                        <span
                          style={{
                            width: 150,
                            paddingRight: 10,
                          }}
                        >
                          {quiz.format ===
                            'multiple_choice'
                            ? 'Multipla Escolha'
                            : 'Verdadeiro/Falso'}
                        </span>
                        <span
                          style={{
                            width: 90,
                            padding: '0 10px',
                            textAlign: 'center',
                          }}
                        >
                          {quiz.number_questions}
                        </span>
                        <span
                          style={{
                            width: 190,
                            padding: '0 10px',
                            textAlign: 'center',
                          }}
                        >
                          {quiz.number_answers}
                        </span>
                        <span
                          style={{
                            width: 70,
                            padding: '0 10px',
                            textAlign: 'center',
                          }}
                        >
                          {quiz.timer}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div> */}
            <Button
              variant="contained"
              color="primary"
              disabled={valueCpf.length < 11 || !isValidCpf(valueCpf) || quiz.loaded || allQuizzes.length === 0}
              onClick={() => this.startQuiz(valueCpf)}
              style={{ width: 300 }}
            >
              Iniciar Quiz
            </Button>
          </div>
        )}

        {showLoading ? (
          <div style={{ marginTop: 30 }}>
            <Loading visible={true} />
          </div>
        ) : null}

        {!showLoading && quiz.loaded ? (
          <div style={{ marginTop: 30 }}>{quiz.loaded ? this.renderQuiz() : null}</div>
        ) : null}

        {/* {!showLoading && extraQuestion && quiz.loaded ? (
          <div style={{ marginTop: 30 }}>
            {quiz.loaded ? this.renderQuiz() : null}
          </div>
        ) : null} */}

        {!showLoading && quizResult !== null ? <div style={{ marginTop: 30 }}>{this.renderQuizResult()}</div> : null}
      </main>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(QuizContent))
