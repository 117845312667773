import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MainNavbar from 'components/MainNavbar'
// import Sidebar from 'components/Sidebar'
import jwtDecode from 'jwt-decode'
import styles from './styles'

const PrivateRoute = ({
  component: Component, title, ...rest
}) => {
  const classes = styles()
  const access_token = useSelector(state => state.auth.access_token)
  const groups = useSelector(state => state.auth.groups)



  const isAuth = () => {
    if (access_token) {
      const decoded = jwtDecode(access_token)
      const currentTime = Date.now() / 1000
      if (decoded.exp < currentTime) {
        return false
      }
      return true
    }
    if (access_token === null) {
      return false
    }
    return false
  }

  return (
    <Route
      {...rest}
      render={props => (isAuth() ? (
        <div className={classes.container}>
          {/* <Sidebar /> */}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MainNavbar groups={groups} />
            <div className={classes.header}>
              <h1 className={classes.title}>{title}</h1>
              <div className={classes.component}>
                <Component {...props} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ))
      }
    />
  )
}

export default PrivateRoute
