import axios from 'axios'

export async function login(data) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/users/login`,
    headers: {
      Authorization: process.env.REACT_APP_AUTENTICADOR_AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
    // eslint-disable-next-line func-names
    validateStatus: function(status) {
      return status >= 200 && status <= 302
    },
  })
  return res
}

export async function register(data) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/users/signup`,
    headers: {
      Authorization: process.env.REACT_APP_AUTENTICADOR_AUTH,
      'Content-Type': 'application/json',
    },
    data,
  })
  return res
}

export async function recoverPasswd(formData) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/passwords/forgot`,
    headers: {
      Authorization: process.env.REACT_APP_AUTENTICADOR_AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}

export async function changePassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/passwords/change`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: formData,
  })
  return res
}

export async function confirmEmail(formData) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/users/confirm`,
    headers: {
      Authorization: process.env.REACT_APP_AUTENTICADOR_AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}

export async function confirmRecoverPassword(formData) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_COGNITO_V2}/passwords/forgot/confirm`,
    headers: {
      Authorization: process.env.REACT_APP_AUTENTICADOR_AUTH,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: formData,
  })
  return res
}
