import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

export const CardTitle = styled.h1`
  width: 98%;
  /* height: 29px; */
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => {
  if (props.loading){
    return '#666'
  } if (!props.loading && props.processed){
      return '#00aa00 !important'
    }
    return '#666'
  }};
`

export const TitleNormal = styled.h2`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
`
export const TitleBold = styled.h2`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
`
export const LineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 30px;
  /* background-color: red; */
`

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  min-width: 100%;
  margin-bottom: 5px;
  @media (max-width: 1170px) {
    width: auto;
  }
  /* background-color: green; */
`

export const LineTitle = styled.div`
  width:160px;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.29;
  letter-spacing: normal;
  color: #666666;
  /* background-color: blue; */
`
export const LineContent = styled.div`
  width: 230px;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.29;
  letter-spacing: normal;
  color: #333333;
  /* background-color: yellow; */

`

export const StatusConsult = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledInput = styled.input`
  width: 267px;
  height: 40px;
  padding: 10px;
  border-radius: 13px;
  border: solid 1px #cccccc;
  background-color: #FFF;
  font-size: 18px;
  font-family: 'Barlow Condensed', sans-serif;
`

export const Input = (props) => {
  return (
    <div style={{marginBottom: 20}}>
      <StyledInput {...props} autoComplete="off" />
      <div style={{marginLeft: 10}}>
        {
          _.isString(props.helperText)
            ? <p>{props.helperText}</p>
            : props.helperText.map((str, i) => <p key={i.toString()}>{str}</p>)
        }
      </div>
    </div>
)
}
